/* Texto */

.home-p {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
}

.home-p a {
    color: #202020;
    text-decoration: underline !important;
    text-decoration-color: #ED1C24 !important;
}

.home-p a:hover {
    color: #ED1C24;
}


/* Estilos da barra de busca */

.home-input {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 0.9rem !important;
    line-height: 18px !important;
    font-weight: 300 !important;
    color: #495057;
    border: 1px solid #ced4da !important;
    border-right: 0px !important;
    border-radius: .25rem;
    transition: none !important;
}

.home-input:focus {
    box-shadow: none !important;
    border: 1px solid #ED1C24 !important;
    border-right: 0px !important;
}

.search-button {
    height: 35.6px !important;
    border: 0px !important;
    padding: 9.8px 12px !important;
    background-color: #ED1C24 !important;
}

.search-button:focus {
    box-shadow: none !important;
}

.search-button:hover {
    background-color: #000000 !important;
    transition-duration: 0.3s;
}

.search-icon {
    vertical-align: baseline !important;
    margin-bottom: 1px !important;
}
