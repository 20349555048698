/* LAYOUT da Justificativa, Histórico e Colaboradores */

.extra-links-container {
    margin-top: 30px;
    max-width: 960px !important;
    padding: 0px !important;
    overflow-x: hidden !important;
}

.extra-links-row {
    margin-top: 0px;
    margin-left: -27px !important;
    margin-right: -27px !important;
}

.extra-links-col {
    padding: 0px 47px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}


/* Links e ícones */

.extra-links-container a {
    color: #202020;
    text-decoration: underline !important;
    text-decoration-color: #ED1C24 !important;
}

.extra-links-container a:hover {
    color: #ED1C24;
}

.extra-links-icon {
    max-width: 80px !important;
    max-height: 80px !important;
}


/* LAYOUT da seção de Colaboradores */

.extra-links-colab-row {
    margin-top: 0px;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.extra-links-colab-col {
    padding: 0px 10px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.extra-links-colab-col p {
    font-size: 0.8rem !important;
}