/* Seção de Licença */

.license {
    max-width: 960px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
}

.license-mobile {
    margin-top: 60px;
}

.license h6 {
    margin-right: 10px !important;
}

.copyrights {
    width: 68px;
    height: 16px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    margin-left: 10px
}

.gov {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100%;
    max-width: 960px;
}

.gov-mobile {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    max-width: 960px;
    padding: 20px;
}

.gov h6 {
    list-style-type: none;
    padding: 20px;
    text-align: right;
}

.first-item {
    margin-left: auto !important;
}


/* Barra vermelha */

.red-bar {
    background-color: #ed1c24;
    height: 60px;
    width: 100%;
    margin-top: 40px;
}


/* Imagem dos blocos */

.blocos {
    display: flex;
    justify-content: center;
}

.imagem-footer {
    width: 100%;
    min-width: 830px;
    max-width: 920px;
    position: absolute;
    z-index: -1 !important;
}


/* Créditos e Links Extras */

.texts {
    width: 100%;
    max-width: 960px;
    margin: 60px auto;
    display: flex;
    flex-direction: row;
}

.credits {
    width: 50%;
    padding-left: 20px;
}

.credits-mobile {
    width: 100% !important;
}

.credits a {
    color: #202020;
    text-decoration: underline !important;
    text-decoration-color: #ED1C24 !important;
}

.credits a:hover {
    color: #ED1C24;
}

.extra-links {
    padding-right: 10px;
    margin-top: -10px;
    margin-left: auto;
}

.extra-links p {
    cursor: pointer;
    text-align: right;
    padding: 10px;
}

.extra-links p::after {
    content: "";
    display: block;
    width: 30px;
     height: 3px;
    background-color: #ED1C24;
    margin-top: 3px;
    margin-left: auto;
}

.extra-links p:hover:after {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    background-color: #ED1C24;
    margin-top: 3px;
    animation-name: grow;
    animation-duration: 0.6s;
    margin-left: auto;
}


/* Redes sociais */

.social-midia {
    text-align: right;
}

.social-midia img {
    width: 45px;
    height: 45px;
    padding: 10px;
}

.social-midia-mobile {
    text-align: left;
}

.social-midia-mobile img {
    width: 60px;
    height: auto;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}