.tam-header {
    height: 60px;
    width: 100%;
    display: none;
}

.show-tam-header {
    display: block;
}

.tam-header-mobile {
    height: 60px;
    width: 100%;
    display: block;
}
