body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Textos */
h1 {
    font-family: 'Signika', sans-serif !important;
    color: #202020 !important;
    font-size: 1.6rem !important;
    line-height: 25px !important;
    margin: 0px !important;
}

h2 {
    font-family: 'Signika', sans-serif !important;
    color: #202020 !important;
    font-size: 1.25rem !important;
    line-height: 25px !important;
    margin: 0px !important;
}

h3 {
    font-family: 'Signika', sans-serif !important;
    font-weight: 300 !important;
    font-size: 1.25rem !important;
    line-height: 18px !important;
    letter-spacing: 0.03em !important;
    color: #202020;
}

h6 {
    font-family: 'Signika', sans-serif !important;
    font-weight: 600 !important;
    font-size: 0.9rem !important;
    line-height: 18px !important;
    letter-spacing: 0.03em !important;
    color: #202020;
    margin: 0px !important;
}

p {
    font-family: 'Signika', sans-serif !important;
    font-weight: 300 !important;
    font-size: 0.9rem !important;
    line-height: 18px !important;
    letter-spacing: 0.03em !important;
    color: #202020;
    margin: 0px !important;
}

a {
    text-decoration: none !important;
}


/* Scrollbar */

html ::-webkit-scrollbar{
    width: 7px;
}

html ::-webkit-scrollbar-track {
    background-color:#F5F5F5;
}

html ::-webkit-scrollbar-thumb {
    background: #CED4DA; 
    border-radius: 10px;
}

html ::-webkit-scrollbar-thumb:hover {
    background: #AFAAD3;
    transition-duration: 0.6s;
}