/* LAYOUT da imagem */

.TopHeader {
    display: flex;
    justify-content: center;
}

.imagem-topheader {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 830px;
    max-width: 920px;
    margin-left: 20px;
    margin-right: 20px;
}


/* Função para que a TopHeader suma quando a tela tiver um tamanho <= 1024px */

@media only screen and (max-width: 1024px) {
    .imagem-topheader {
        display: none;
    }
}