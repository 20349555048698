/* Barra da header */

.header {
    width: 100%;
    background-color: #ed1c24;
    max-height: 60px;
    z-index: 20;
}

.red-header-fixed {
    position: fixed;
    top: 0px;
}


/* Lista (páginas da header) */

.header ul {
    display: flex;
    margin: auto;
    width: 100%;
    max-width: 960px;
    justify-content:space-between; 
    padding: 0px 8px;
}

.header li {
    list-style-type: none;
    cursor: pointer;
}


/* Estilo dos botões dropdown e do botão de Início */

.header-dropdown-toggle {
    color: #fff !important;
    font-family: 'Signika', sans-serif !important;
    font-size: 1rem !important;
    text-transform: uppercase !important;
    line-height: 25px !important;
    text-align: center !important;
    letter-spacing: 0.03em !important;
    font-weight: 400 !important;
    padding: 16.6px 12px !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.header-dropdown-toggle:focus {
    box-shadow: none !important;
}


/* Animação da barrinha branca que cresce ao passar o mouse por cima dos itens */

@keyframes alongar {
    from {
        width: 0%;
    }
    to {
        width: 30px;
    } 
}

.header-dropdown-toggle:hover:after {
    content: "";
    display: block;
    width: 30px;
    height: 3.2px;
    background-color: white;
    margin-top: 8px;
    animation-name: alongar;
    animation-duration: 0.6s;
}


/* Estilo do menu de dropdown e seus botões */

.header-dropdown-menu {
    margin-top: -12px !important;
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    border-bottom-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem !important;
    max-height: 350px;
    overflow-y: scroll;
}

.header-dropdown-menu:focus {
    outline: none !important;
}

@media only screen and (max-width: 1024px) {
    .header-dropdown-menu {
        max-height: none;
    }
}

.header-dropdown-menu button {
    color: #202020 !important;
    font-family: 'Signika', sans-serif !important;
    font-size: 1rem !important;
    line-height: 25px !important;
    letter-spacing: 0.03em !important;
    font-weight: 400 !important;
}

.header-dropdown-menu button:focus, .header-dropdown-menu button:active {
    background-color: #ED1C24 !important;
    color: white !important;
    outline: none !important;
}

.dropdown-item{
    white-space: normal !important;
    max-width: 400px;
    min-width: 300px;
    overflow-wrap: break-word;
}

.dropdownOpen::after {
    content: "";
    display: block;
    width: 30px;
    height: 3.2px;
    background-color: white;
    margin-top: 8px;
}


/* Estilos para mobile */

.collapse {
    width: 100%;
}

.navbar {
    background-color: #ED1C24 !important;
}

.header-dropdown-toggle-mobile {
    color: #fff !important;
    font-family: 'Signika', sans-serif !important;
    font-size: 1rem !important;
    line-height: 25px !important;
    letter-spacing: 0.03em !important;
    font-weight: 400 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

button:active, button:focus {
    outline: 0px !important;
}

.header-dropdown-menu-mobile {
    max-height: 315px;
    overflow-y: scroll;
    overflow-x: hidden;
}