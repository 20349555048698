/* LAYOUT de linhas e colunas */

.resources-row {
    margin-top: 0px;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.resources-col {
    padding: 0px 10px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}


/* LAYOUT dos cards */

.resources-card {
    margin-top: 20px;
    border: 1px solid #ED1C24;
    border-radius: 0.25rem;
    margin-right: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.details-card {
    background-color: #F5F5F5;
    border-radius: 0.25rem;
}

.details-card h3 {
    padding-left: 20px;
    padding-top: 20px;
}

.details-card p {
    padding-left: 20px;
    padding-right: 20px;
}


/* Ícones das mídias */

.midia-icon {
    height: 40px;
    width: auto;
}

.icon-alignment {
    display: flex;
    flex-direction: row-reverse;
}

@media only screen and (max-width: 760px) {
    .icon-alignment {
        display: flex;
        flex-direction: row;
    }
}


/* Collapse do filtro */

.filter-collapse {
    max-height: 500px;
    overflow-y: scroll;
}

@media only screen and (max-width: 1024px) {
    .collapse {
        
    }
}


/* Botões */

.button {
    height: 35.6px !important;
    border: 0px !important;
    padding: 9.8px 12px !important;
    background-color: #ED1C24 !important;
}

.button:focus {
    box-shadow: none !important;
}

.button p {
    color: #ffffff !important;
    font-weight: 600 !important;
}

.button:hover {
    background-color: #000000 !important;
    transition-duration: 0.3s;
}

@media only screen and (max-width: 1024px) {
    .button:hover {
        background-color: #ED1C24 !important;
    }

    .button:active {
        background-color: #000000 !important;
    }
}


/* Botão de scroll to top */

.scroll-to-top-button {
    position: fixed;
    right: 60px;
    bottom: 60px;
    background-color: #ED1C24;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 1000;
}

.scroll-to-top-button:hover {
    background-color: #000000;
    transition-duration: 0.6s;
}

.scroll-to-top-button img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
    width: 20px;
}

@media only screen and (max-width: 760px) {
    .scroll-to-top-button {
        right: 30px;
        bottom: 30px;
        width: 40px;
        height: 40px;
    }
}

@media only screen and (max-width: 1024px) {
    .scroll-to-top-button:hover {
        background-color: #ED1C24;
    }

    .scroll-to-top-button:active {
        background-color: #000000 !important;
    }
}



/* NOS DETALHES:  */

/* Card em cinza com duração, objetivos, conteúdos e créditos */

.filters-card-body {
    padding-top: 0px !important;
}

.filters-card-body p {
    color: #838383;
    cursor: pointer;
}

@media only screen and (min-width: 1024px) {
    .filters-card-body p:hover {
        color: #ED1C24;
    }
}



/* Div onde ficam os links de download */

.downloads-area {
    padding: 0px 47px 0px 0px !important;
    margin-top: 30px !important;
    margin-bottom: 30px;
}

@media only screen and (max-width: 1024px) {
    .downloads-area {
        padding: 0px 47px !important;
        margin-top: 30px !important;
        margin-bottom: 30px;
    }
}

.downloads-area a {
    color: #202020;
    text-decoration: underline !important;
    text-decoration-color: #ED1C24 !important;
}

.downloads-area a:hover {
    color: #ED1C24;
}


/* Para a exibição dos vídeos */

.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}


/* Para a exibição dos players de audio */

audio:focus {
    outline: none;
}

/* Tentativa de copiar o botão do Twitter sem usar o site deles */

.twitter-button {
	box-sizing: border-box;
	padding: 3px 3px 6px 10px;
	background-color: #1b95e0;
	color: #fff;
	border-radius: 4px;
	font-weight: 500;
	cursor: pointer;
	font: normal normal normal 13px/20px 'Helvetica Neue',Arial,sans-serif;
	width: 75px;
	height: 29px;
	font-size: 13px;
	white-space: nowrap;
}

.twitter-button:hover {
	background-color: #0c7abf;
}

.twitter-button svg {
	margin-top: 5px;
	float: left;
	height: 13px;

}

.twitter-button-text {
	margin-top: 1px;
	margin-bottom: 0px;
	margin-left: 4px;
	margin-right: 0px;
    float: left;
}

	
